import ThreeGlobe from "three-globe";
import {
  WebGLRenderer,
  Scene
} from "three";
import * as dat from 'lil-gui'
import * as THREE from 'three';

import {
  PerspectiveCamera,
  AmbientLight,
  DirectionalLight,
  Color,
  Fog,
  // AxesHelper,
  // DirectionalLightHelper,
  // CameraHelper,
  PointLight,
  SphereGeometry,
} from "three";
import {
  OrbitControls
} from "three/examples/jsm/controls/OrbitControls.js";
// import { createGlowMesh } from "three-glow-mesh";
import countries from "./Components/files/globe-data-min.json";
import travelHistory from "./Components/files/my-flights.json";
import airportHistory from "./Components/files/my-airports.json";

export default class Sketch {
  constructor(options) {
    this.container = options.dom;
    this.Globe = null;
    this.windowHalfX = window.innerWidth / 2;
    this.windowHalfY = this.container.clientHeight / 2;
    this.mouseX = 0;
    this.mouseY = 0;
    this.isPlaying = false;

    this.parameters = {
      rotationY: -0.8,
      rotationX: -0.2,
    }
    this.renderer = new WebGLRenderer({
      antialias: true,
      alpha: true
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(window.innerWidth, this.container.clientHeight);
    // renderer.outputEncoding = THREE.sRGBEncoding;
    this.container.appendChild(this.renderer.domElement);

    // Initialize scene, light
    this.scene = new Scene();
    this.scene.add(new AmbientLight(0xbbbbbb, 0.3));

    // Initialize camera, light
    this.camera = new PerspectiveCamera(50, window.innerWidth / this.container.clientHeight, 0.1, 1000);
    this.camera.position.set(0, 0, window.innerWidth > 768 ? 300 : 800)

    this.dLight = new DirectionalLight(0xffffff, 0.8);
    this.dLight.position.set(-800, 2000, 400);
    this.camera.add(this.dLight);

    this.dLight1 = new DirectionalLight(0x7982f6, 1);
    this.dLight1.position.set(-200, 500, 200);
    this.camera.add(this.dLight1);

    this.scene.add(this.camera);

    // Additional effects
    this.scene.fog = new Fog(0x535ef3, 400, 2000);

    // Helpers
    // const axesHelper = new AxesHelper(800);
    // scene.add(axesHelper);
    // let helper = new DirectionalLightHelper(dLight);
    // scene.add(helper);
    // let helperCamera = new CameraHelper(dLight.shadow.camera);
    // scene.add(helperCamera);

    // Initialize controls
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.enableDamping = true;
    this.controls.enableZoom = false;
    this.controls.dynamicDampingFactor = 0.01;
    this.controls.enablePan = false;
    this.controls.minDistance = 200;
    this.controls.maxDistance = 900;
    this.controls.rotateSpeed = 0.5;
    this.controls.zoomSpeed = 1;
    this.controls.autoRotate = false;

    this.controls.minPolarAngle = Math.PI / 3.5;
    this.controls.maxPolarAngle = Math.PI - Math.PI / 3;

    window.addEventListener("resize", this.onWindowResize.bind(this), false);
    document.addEventListener("mousemove", this.onMouseMove.bind(this));
    this.initGlobe()
  }

  onMouseMove(event) {
    this.mouseX = event.clientX - this.windowHalfX;
    this.mouseY = event.clientY - this.windowHalfY;
    // console.log("x: " + mouseX + " y: " + mouseY);
  }

  onWindowResize() {
    this.camera.aspect = window.innerWidth / this.container.clientHeight;
    this.camera.updateProjectionMatrix();
    this.windowHalfX = window.innerWidth / 1.5;
    this.windowHalfY = this.container.clientHeight / 1.5;
    this.renderer.setSize(window.innerWidth, this.container.clientHeight);
  }

  initGlobe() {
    // Initialize the Globe
    if (this.Globe !== null) {
      this.scene.remove(this.Globe)
    }
    this.Globe = new ThreeGlobe({
        waitForGlobeReady: true,
        animateIn: true,
      })
      .hexPolygonsData(countries.features)
      .hexPolygonResolution(3)
      .hexPolygonMargin(0.7)
      .showAtmosphere(true)
      .atmosphereColor("#3a228a")
      .atmosphereAltitude(0.25)

      this.Globe.position.set(0, 0, 0)
      this.Globe.matrixWorldNeedsUpdate = true;
      const obj = new THREE.Box3().setFromObject(this.Globe);
      const offsetY = -obj.min.y;

      const center = new THREE.Vector3()
      obj.getCenter(center);

       // Reposition the model
       this.Globe.position.sub(center)
       
    // .hexPolygonColor((e) => {
    //   if (
    //     ["MNG", "KOR", "THA", "RUS", "ARE", "IDN", "US1", "MYS"].includes(
    //       e.properties.ISO_A3
    //     )
    //   ) {
    //     return "rgba(255,255,255, 1)";
    //   } else return "rgba(255,255,255, 0.7)";
    // });

    // NOTE Arc animations are followed after the globe enters the scene
    setTimeout(() => {
      this.Globe.arcsData(travelHistory.flights)
        .arcColor((e) => {
          return '#FFCC00'
        })
        .arcAltitude((e) => {
          return e.arcAlt;
        })
        .arcStroke((e) => {
          // return e.status ? 0.1 : 0.1;
          return 0.1;
        })
        .arcDashLength(4)
        .arcDashGap(4)
        .arcDashAnimateTime(1000)
        .arcsTransitionDuration(1000)
        .arcDashInitialGap((e) => e.order * 1)
        .labelsData(airportHistory.airports)
        .labelColor(() => "#ffcb21")
        .labelDotOrientation((e) => {
          return e.text === "ALA" ? "top" : "right";
        })
        .labelDotRadius(0.3)
        .labelSize((e) => e.size)
        .labelText("city")
        .labelResolution(6)
        .labelAltitude(0.01)
        .pointsData(airportHistory.airports)
        .pointColor(() => "#ffffff")
        .pointsMerge(true)
        .pointAltitude(0.07)
        .pointRadius(0.05);
    }, 1000);

    this.Globe.rotateY(this.parameters.rotationY);
    this.Globe.rotateZ(this.parameters.rotationX);

    this.globeMaterial = this.Globe.globeMaterial();
    this.globeMaterial.color = new Color(0x3a228a);
    this.globeMaterial.emissive = new Color(0x220038);
    this.globeMaterial.emissiveIntensity = 0.1;
    this.globeMaterial.shininess = 1;

    // NOTE Cool stuff
    // globeMaterial.wireframe = true;
    this.scene.add(this.Globe);
  }

  play() {
    if (!this.isPlaying) {
      this.isPlaying = true;
      this.render()
    }
  }

  stop() {
    this.isPlaying = false;
  }

  render() {
    if (!this.isPlaying) return;
    this.camera.position.x =
      Math.abs(this.mouseX) <= this.windowHalfX ?
      (this.mouseX / 2) * 0.05 :
      0;
    this.camera.position.y = (-this.mouseY / 2) * 0.05;
    this.camera.lookAt(this.Globe.position);
    this.controls.update();
    this.renderer.render(this.scene, this.camera);
    requestAnimationFrame(this.render.bind(this));
  }
}