import lottie from "lottie-web";
import ScrollTrigger from "gsap/ScrollTrigger";
import gsap from "gsap";
import Sketch from "./Globe";

gsap.registerPlugin(ScrollTrigger)

const processAnimation = ['upload', 'process', 'production', 'qualityControl', 'delivery']
const animArray = []
let processTL, networkTL;
const networkSection = document.getElementById('network');

const globe = new Sketch({
  dom: document.querySelector('#globe')
})
globe.play()

const observer = new IntersectionObserver((entries) => {
  for (const entry of entries) {
    const intersecting = entry.isIntersecting;
    if (intersecting) {
      networkSection.querySelector('#globe').classList.add('active')
      globe.play()
    } else {
      globe.stop();
    }
  }
}, {
  threshold: .15
});


observer.observe(networkSection);

window.onload = function () {
  globe.stop()
  const resizeObserver = new ResizeObserver(entries => {
    if (window.innerWidth > 768) {
      ScrollTrigger.refresh();
    }
  })
  resizeObserver.observe(document.body)
  init()
}

function init() {

  processTL = gsap.timeline({
      scrollTrigger: {
        trigger: '.section-process',
        start: 'top top',
        end: 'bottom+=300% center',
        pin: true,
        scrub: 1,
      }
    })
    .fromTo('.process-content__heading', {
      y: '150%',
      autoAlpha: 0
    }, {
      y: -50,
      autoAlpha: 1
    })
  const processTargets = gsap.utils.toArray(".process-content__item");

  processTargets.forEach((target, index) => {
    activateAnim(index)
    let targetIndex = processTargets.length - (index + 1)
    processTL
      .fromTo(target, {
        y: `200%`,
      }, {
        y: 0,
        opacity: 1,
        onStart: () => animArray[index].play()
      }, .25 + index * 0.45)
      .to(target, {
        delay: -.5,
        scale: 1 - (targetIndex * 0.1),
        y: ((1.25 * targetIndex) * 10) * -1,
        transformOrigin: 'top center',
      }, .25 + (index * 0.45) + 1)
      .to(target, {
        opacity: 1 - (targetIndex * 0.1),
        duration: .1,
        onComplete: () => animArray[index].stop()
      }, .25 + (index * 0.45) + 1)

  })

  networkTL = gsap.timeline({
    scrollTrigger: {
      trigger: '.section-network',
      start: 'center center+=20%',
      end: 'center top',
    }
  })

  networkTL
    .from('.counter-number', {
      y: '100%',
      autoAlpha: 0,
      stagger: .15,
      ease: 'power4.out',
      duration: 2
    })
    .from('.counter-number h2', {
      textContent: 0,
      ease: 'power1.out',
      snap: {
        textContent: 2
      },
      duration: 2,
      stagger: .25,
      onComplete: function () {
        this.targets().forEach(element => {
          const number = element.textContent
          element.innerHTML = `${new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(+number)}+`
        });
      }
    }, 1)
    .from('.counter-circle circle', {
      strokeDashoffset: 1000,
      ease: 'power2.inOut',
      duration: 2,
      stagger: .5
    }, 0)

}

let mm = gsap.matchMedia();
mm.add("(min-width: 768px)", () => {

  gsap.timeline({
      scrollTrigger: {
        trigger: '.our-standard-animation',
        scrub: 1,
        start: "center center",
      },
    })
    .from('.standard-lists .standard-text', {
      delay: 1.5,
      autoAlpha: 0,
      y: 50,
      stagger: 1
    }, 2)
})

gsap.utils.toArray("#slsVideo").forEach(video => videoScrub(video, {
  scrollTrigger: {
    trigger: window.innerWidth > 768 ? '.section-standard' : '.our-standard-animation',
    start: "center center",
    end: "bottom+=200% top",
    scrub: true,
    pin: true
  }
}));

function videoScrub(video, vars) {
  video = gsap.utils.toArray(video)[0]; // in case selector text is fed in.
  let once = (el, event, fn) => {
      let onceFn = function () {
        el.removeEventListener(event, onceFn);
        fn.apply(this, arguments);
      };
      el.addEventListener(event, onceFn);
      return onceFn;
    },
    prepFunc = () => {
      video.play();
      video.pause();
    },
    prep = () => once(document.documentElement, "touchstart", prepFunc),
    src = video.currentSrc || video.src,
    tween = gsap.fromTo(video, {
      currentTime: 0
    }, {
      paused: true,
      immediateRender: false,
      currentTime: video.duration || 1,
      ease: "none",
      ...vars
    }),
    resetTime = () => (tween.vars.currentTime = video.duration || 1) && tween.invalidate();
  prep();
  video.readyState ? resetTime() : once(video, "loadedmetadata", resetTime);
  return tween;
}

function activateAnim(index) {
  const anim = processAnimation[index];
  const container = document.querySelector(`#${anim}`)
  const lottieAnim = lottie.loadAnimation({
    container: container,
    renderer: 'svg',
    loop: true,
    autoplay: false,
    path: `/animation/${anim}.json`
  });

  animArray.push(lottieAnim)
}